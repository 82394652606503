import React from "react"
import { shape } from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { color } from "../components/_globalSettings/skin"
import { breakpoint } from "../components/_globalSettings/var"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import VideoList from "../components/Videos/list"
import {
	typography,
	Heading3,
	Heading4,
} from "../components/_globalSettings/typography"
import ArrowRight from "../images/icons/breadcrumb-arrow.svg"
import SlidesLive from "../components/SlidesLive"

const VideoListWrap = styled.div`
	h4 {
		padding-bottom: 20px;
		padding-top: 80px;
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		h4 {
			padding-top: 40px;
			padding-bottom: 0;
		}
	}
`

const BreadcrumbNav = styled.div`
	margin-top: 81px;
	margin-bottom: 11px;
	width: 100%;
	display: flex;
	align-items: center;
	${typography.LabelS};
	.breadcrumb__parent {
		color: ${color.main.text3};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.breadcrumb__child {
		color: ${color.main.text1};
		font-weight: 500;
	}
	img {
		margin-left: 9px;
		margin-right: 14px;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 20px;
		img {
			display: none;
		}
		.breadcrumb__child {
			margin-top: 5px;
		}
	}
`

const VideoWrap = styled.div`
	padding-bottom: 80px;
	video {
		max-width: 100%;
	}
	h3 {
		padding-bottom: 24px;
		width: 61.5%;
	}
	p {
		${typography.BodyL};
		color: ${color.main.text2};
		width: 31.6%;
		padding-left: 3.45%;
		border-left: 1px solid ${color.light.n400};
		margin-left: 3.45%;
	}
	.video__info {
		display: flex;
		width: 100%;
		margin-top: 22px;
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		padding-bottom: 40px;
		h3 {
			font-size: 28px;
		}
	}
	@media (max-width: ${breakpoint.default.to}px) {
		.video__info {
			flex-direction: column;
		}
		p {
			width: 100%;
			padding-left: 0;
			margin-left: 0;
			border: none;
		}
		h3 {
			width: 100%;
		}
	}
`

const Video = ({ pageContext }) => (
	<Layout {...pageContext}>
		<Row>
			<BreadcrumbNav>
				<Link to="/zamereno-na-vyvoj" className="breadcrumb__parent">
					Zaměřeno na vývoj
				</Link>
				<img src={ArrowRight} alt="Šipka" />
				<span className="breadcrumb__child">{pageContext.videoName}</span>
			</BreadcrumbNav>
		</Row>
		<Row>
			<VideoWrap>
				<SlidesLive presentationId={pageContext.videoEmbedId} />
				<div className="video__info">
					<Heading3>{pageContext.videoName}</Heading3>
					<p>{pageContext.description}</p>
				</div>
			</VideoWrap>
		</Row>
		<Row color={color.light.n700}>
			<VideoListWrap>
				<Heading4>Související videa</Heading4>
				<VideoList {...pageContext.videos} />
			</VideoListWrap>
		</Row>
	</Layout>
)

Video.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Video
