import styled from "styled-components"
import { color } from "../_globalSettings/skin"
import { breakpoint } from "../_globalSettings/var"
import { typography } from "../_globalSettings/typography"

export const StyledVideosLeft = styled.div`
	width: 37.5%;
	margin-right: 13.7%;
	h2 {
		padding-bottom: 40px;
	}
	p {
		font-size: 24px;
		line-height: 36px;
		color: ${color.main.text2};
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 71px;
		p {
			font-size: 18px;
			line-height: 30px;
		}
	}
`

export const StyledVideosRight = styled.div`
	width: 48.8%;
	.video__wrapper {
		position: relative;
		z-index: 1;
		video {
			max-width: 100%;
		}
	}
	.video__wrapper::after {
		content: "";
		position: absolute;
		top: 14px;
		left: 14px;
		border: 2px solid ${color.main.primary1};
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	h5 {
		margin-top: 25px;
		padding-bottom: 24px;
		a {
			color: ${color.main.text1};
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	p {
		${typography.BodyL};
		color: ${color.main.text2};
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: auto;
		max-width: 620px;
		margin: auto;
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		.video__wrapper::after {
			display: none;
		}
	}
`

export const StyledList = styled.div`
	padding-top: 60px;
	padding-bottom: 20px;
	.videos__wrapper {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.videos__single {
			margin-bottom: 80px;
			width: 31.5%;
			video {
				max-width: 100%;
			}
			h5 {
				margin-top: 22px;
				paddibng-bottom: 16px;
				a {
					color: ${color.main.text1};
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			p {
				${typography.BodyL};
				color: ${color.main.text2};
			}
		}
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		padding-top: 40px;
		padding-bottom: 0;
		.videos__wrapper {
			flex-direction: column;
			.videos__single {
				margin-bottom: 40px;
				width: 100%;
				video {
					width: 100%;
				}
			}
		}
	}
`
