import React from "react"
import { Link } from "gatsby"
import { string, shape, number, arrayOf, bool } from "prop-types"
import { useLocation } from "@reach/router"
import { StyledList } from "./style"
import { Heading5 } from "../_globalSettings/typography"
import SlidesLive from "../SlidesLive"

const VideoList = ({ nodes }) => {
	const location = useLocation()
	return (
		<StyledList>
			<div className="videos__wrapper">
				{nodes.map(video => {
					return (
						location.pathname !== `/zamereno-na-vyvoj/${video.url}` && (
							<div className="videos__single" key={video.url}>
								<SlidesLive
									key={video.url}
									presentationId={video.videoEmbedId}
								/>
								<Heading5>
									<Link to={`/zamereno-na-vyvoj/${video.url}`}>
										{video.videoName}
									</Link>
								</Heading5>
								<p>{video.description}</p>
							</div>
						)
					)
				})}
			</div>
		</StyledList>
	)
}

export const VideoListProps = {
	nodes: arrayOf(
		shape({
			videoName: string.isRequired,
			videoEmbedId: number.isRequired,
			url: string.isRequired,
			seo: string.isRequired,
			description: string.isRequired,
			pinned: bool,
		})
	),
}

VideoList.propTypes = {
	...VideoListProps,
}

export default VideoList
